import React from "react"
import TutorialLayout from "../../components/TutorialLayout"
import IMG from "../../images/Step_two.svg"
import STEP_THREE_CONTENT from "../../images/StepThreeContent.svg"
import { Link } from "gatsby"

const Second = () => (
  <TutorialLayout
    imageSrc={IMG}
    step="3"
    title="Crează un cont nou"
    description="Pentru a crea un cont nou folosește adresa ta de email și datele personale apoi confirmă emailul primit."
    buttonLink="/salut/locDeConsumQuest"
  >
    <img src={STEP_THREE_CONTENT} alt="App Store" style={{ width: "100%" }} />
    <ol style={{ paddingLeft: 20, marginTop: 16 }}>
      <li style={{ marginBottom: 6 }}>
        Crează un cont nou accesând pagina <a>app.myara.ro</a>
      </li>
      <li style={{ marginBottom: 6 }}>
        Pentru crea un cont nou va trebui să accesezi butonul{" "}
        <b>Înregistrează-te.</b>
      </li>
      <li style={{ marginBottom: 6 }}>
        Formularul de înregistrare necesită datele tale personale și de contact
        și o parolă pentru securitatea contului tău.
      </li>
      <li>
        După completarea cu succes a datelor în formular, vei primi un email
        care conține un link de activare. Pentru a activa contul va trebui să
        urmezi acest link.
      </li>
    </ol>
    <Link to="https://app.myara.ro">
      <button
        style={{
          width: "100%",
          backgroundColor: "#fff",
          height: 48,
          border: "1px solid #ABB2BA",
          cursor: "pointer",
          color: "#2E3338",
          borderRadius: 8,
          fontSize: 16,
          fontWeight: 400,
          marginBottom: 16,
          fontFamily: "Inter",
        }}
      >
        Creaza cont
      </button>
    </Link>
    <span
      style={{
        fontSize: 12,
        lineHeight: "20px",
        fontFamily: "Inter",
      }}
    >
      Se va deschide o fereastră nouă cu formularul de înregistrare.
    </span>
  </TutorialLayout>
)

export default Second
